import { useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { LANG_MAP } from '../constants';
import { useSynthesizeVoiceMutation } from '../graphql/server-graphql-schema';
import { useCurrentSet } from '../hooks/currentSetHook';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';
import { useToast } from '../hooks/ToastNotificationProvider';

const VoiceManagementPage = () => {
  const { id: setId = 'set-id-not-provided' } = useParams();
  const { showToast } = useToast();

  const { refetch, sentenceSets } = useSentenceSetSets();
  const currentSet = useCurrentSet(sentenceSets, setId);

  const initForm = {
    formLang: 'xxx',
  };

  const [queryOverride, setQueryOverride] = useState(false);
  const [synthesizeOverride, setSynthesizeOverride] = useState(false);
  const [limit, setLimit] = useState(0);
  const [synthesizeVoice, { loading: loadingVoice }] = useSynthesizeVoiceMutation();
  const [formData, setFormData] = useState(initForm);

  const filteredSentences = currentSet?.sentences?.length
    ? currentSet.sentences
        .filter((sentence) => {
          // 1. Filter out sentences that don't have translations matching formLang
          const hasTranslation = sentence.translations.find((t) => t.lang === formData.formLang);
          if (formData.formLang !== 'es-MX' && !hasTranslation) {
            return false; // Exclude these sentences
          }

          // 2. If queryOverride is true, pass all sentences
          if (queryOverride) {
            return true;
          }

          // 3. Exclude sentences where formLang is already present in voiceGenerated
          return !sentence.meta?.voiceGenerated?.includes(formData.formLang);
        })
        .map((sentence) => ({
          id: sentence.id,
          lang: sentence.lang,
          text:
            formData.formLang === 'es-MX'
              ? sentence.text
              : sentence.translations.find((t) => t.lang === formData.formLang)?.text || sentence.text, // Fallback if no translation found
          meta: sentence.meta,
        }))
    : [];
  // Adjust the processUpload function to use filteredSentences
  const processUpload = async () => {
    if (!currentSet?.sentences) {
      return;
    }

    if (filteredSentences.length === 0) {
      showToast({ message: 'No missing voices found.', variant: 'error', autohide: true });
      return;
    }

    // Apply limit if needed
    const sentencesToUpload = limit > 0 ? filteredSentences.slice(0, limit) : filteredSentences;

    try {
      await synthesizeVoice({
        fetchPolicy: 'network-only',
        variables: {
          input: {
            setID: setId,
            sentences: sentencesToUpload,
            override: synthesizeOverride,
            lang: formData.formLang,
            type: formData.formLang === 'text' ? 'text' : 'translate',
          },
        },
      });

      showToast({ message: 'Voice synthesis completed successfully!', variant: 'success', autohide: true });
      await refetch(); // re-download the sentences sets
    } catch (error: any) {
      showToast({ message: `Failed to synthesize voices: ${error.message}`, variant: 'error', autohide: false });
    }
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Synthesize Voice</title>
      </Helmet>

      <h1 className="mb-4">Synthesize Voice</h1>
      <p className="mb-4">Get sentences that are missing voice file</p>

      <Form.Group as={Row} className="mb-2">
        <Col>
          <Form.Check
            type="checkbox"
            label="Get all sentences not just the ones missing voice"
            checked={queryOverride}
            onChange={(e) => setQueryOverride(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Force regenerate voice files"
            checked={synthesizeOverride}
            onChange={(e) => setSynthesizeOverride(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Test: Limit to 1 sentence"
            onChange={(e) => setLimit(e.target.checked ? 1 : 0)} // Set limit to 1 if checked, else reset to 0
          />
        </Col>
        <Col>
          <Form.Control as="select" id="formLang" name="formLang" value={formData.formLang} onChange={handleFormChange}>
            <option key={'text'} value={'text'}>
              ------ Show missing voices ------
            </option>
            {Object.entries(LANG_MAP).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          <Button variant="primary" type="button" onClick={() => processUpload()}>
            {!currentSet?.sentences || loadingVoice ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Loading...
              </>
            ) : (
              'Generate Speech'
            )}
          </Button>
        </Col>
      </Form.Group>

      <div className="row mt-4">
        <div className="col">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Text</th>
                <th>Language</th>
                <th>Voices</th>
              </tr>
            </thead>
            <tbody>
              {filteredSentences.map((s, index) => (
                <tr key={s.id}>
                  <td>{index + 1}</td>
                  <td>{s.id}</td>
                  <td>{s.text}</td>
                  <td>{s.lang}</td>
                  <td>{s.meta.voiceGenerated?.join(' , ')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default VoiceManagementPage;

import 'react-markdown-editor-lite/lib/index.css';

import MarkdownIt from 'markdown-it';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import ReactMarkdownEditorLite from 'react-markdown-editor-lite';

import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import {
  SentenceSet,
  SentenceSetUpsertInput,
  useDeleteSentenceSetMutation,
  useUpsertSentenceSetMutation,
} from '../../graphql/server-graphql-schema';
import { useToast } from '../../hooks/ToastNotificationProvider';

interface SetFormProps {
  initialData?: SentenceSet | null; // Optional, used for edit mode
  onClose: () => void; // Callback to close the form
}

const SetForm: React.FC<SetFormProps> = ({ initialData, onClose }) => {
  const initForm = {
    id: undefined,
    name: '',
    notes: '',
    lang: 'es-MX',
    type: 'sentence',
    isPublic: false,
    lesson: '',
    meta: '{}', // Initialize with an empty JSON object
  };

  // Initialize formData with either the initial data or a new form template
  const [formData, setFormData] = useState(() => {
    if (initialData) {
      // Extract lesson content from `meta`
      const initialLesson = initialData.meta ? initialData.meta.lessons?.[0]?.content ?? '' : '';
      return {
        ...initForm,
        ...initialData,
        lesson: initialLesson,
        meta: JSON.stringify(initialData.meta ?? {}, null, 2), // Ensure meta is always a JSON string
        isPublic: initialData.isPublic ?? false, // Ensure isPublic is always boolean
      };
    }
    return initForm;
  });

  const [isEditMode, setIsEditMode] = useState<boolean>(!!initialData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const mdParser = new MarkdownIt();

  const [upsertSentenceSetMutation, { loading: loadingSave }] = useUpsertSentenceSetMutation();
  const [deleteSentenceSetMutation] = useDeleteSentenceSetMutation();

  const { showToast } = useToast();

  useEffect(() => {
    if (initialData) {
      // Reinitialize the form data if the initialData prop changes
      const initialLesson = initialData.meta ? initialData.meta.lessons?.[0]?.content ?? '' : '';
      setFormData({
        ...initForm,
        ...initialData,
        lesson: initialLesson,
        meta: JSON.stringify(initialData.meta ?? {}, null, 2), // Ensure meta is always a JSON string
        isPublic: initialData.isPublic ?? false, // Ensure isPublic is always boolean
      });
      setIsEditMode(true);
    }
  }, [initialData]);

  const handleDeleteSentenceSet = async () => {
    if (formData.id) {
      try {
        showToast({ message: 'Deleting...', variant: 'success', autohide: true });

        await deleteSentenceSetMutation({
          fetchPolicy: 'network-only',
          variables: { id: formData.id },
        });

        showToast({ message: 'Sentence set deleted successfully.', variant: 'success', autohide: true });
        onClose(); // Close the form after deleting
      } catch (err) {
        if (err instanceof Error) {
          showToast({ message: `Error deleting sentence set: ${err.message}`, variant: 'error', autohide: false });
        }
      }
    }
  };

  const handleSaveButtonPress = async () => {
    try {
      showToast({ message: 'Saving...', variant: 'success', autohide: true });

      let parsedMeta;
      try {
        parsedMeta = JSON.parse(formData.meta);
      } catch (error) {
        if (error instanceof Error) {
          showToast({ message: `Invalid JSON format in Meta field: ${error.message}`, variant: 'error', autohide: false });
        }
        return;
      }

      const { lesson, ...restOfFormData } = formData;

      const finalMeta = { ...parsedMeta, lessons: [{ content: lesson }] };

      if (restOfFormData.name !== '') {
        const input: SentenceSetUpsertInput = {
          ...restOfFormData,
          lang: restOfFormData.lang ?? 'es-MX', // Ensure lang is always a string
          meta: finalMeta,
          isPublic: restOfFormData.isPublic ?? false, // Ensure isPublic is always boolean
          schema: 1.0, // Ensure schema is always assigned a valid float value
        };

        await upsertSentenceSetMutation({
          fetchPolicy: 'network-only',
          variables: { input },
        });

        showToast({ message: 'Sentence set saved successfully.', variant: 'success', autohide: true });
        onClose(); // Close the form after saving
      }
    } catch (err) {
      if (err instanceof Error) {
        showToast({ message: `Error saving sentence set: ${err.message}`, variant: 'error', autohide: false });
      }
    }
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
      const { checked } = e.target; // Explicitly get `checked` only when dealing with HTMLInputElement
      setFormData((prevData) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value ?? '' })); // Ensure value is never null or undefined
    }
  };

  const handleLessonChange = ({ text }: { html: string; text: string }) => {
    setFormData((prevData) => ({ ...prevData, lesson: text }));
  };

  return (
    <div className="container mt-4">
      {isEditMode && (
        <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
          Delete
        </Button>
      )}
      <Form className="border rounded bg-body-tertiary p-3">
        <Form.Group as={Row} controlId="formName" className="mb-2">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="4">
            <Form.Control
              type="text"
              name="name"
              autoComplete="off"
              value={formData.name ?? ''} // Ensure value is a valid type
              onChange={handleFormChange}
              placeholder=""
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formType" className="mb-2">
          <Form.Label column sm="2">
            Type
          </Form.Label>
          <Col sm="4">
            <Form.Control as="select" name="type" value={formData.type ?? ''} onChange={handleFormChange}>
              <option value="sentence">Sentence</option>
              <option value="word">Word</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formLang" className="mb-2">
          <Form.Label column sm="2">
            Language
          </Form.Label>
          <Col sm="4">
            <Form.Control type="text" readOnly name="lang" value={formData.lang ?? ''} placeholder="" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formNotes" className="mb-2">
          <Form.Label column sm="2">
            Notes
          </Form.Label>
          <Col sm="10">
            <Form.Control
              rows={3}
              as="textarea"
              name="notes"
              value={formData.notes ?? ''}
              onChange={handleFormChange}
              placeholder=""
            />
          </Col>
        </Form.Group>
        {/* Lesson markdown editor */}
        <Form.Group as={Row} controlId="formLesson" className="mb-2">
          <Form.Label column sm="2">
            Lesson
          </Form.Label>
          <Col sm="10">
            <ReactMarkdownEditorLite
              id="formLesson"
              value={formData.lesson}
              onChange={handleLessonChange}
              renderHTML={(text) => mdParser.render(text)}
            />
          </Col>
        </Form.Group>
        {/* Meta JSON input */}
        <Form.Group as={Row} controlId="formMeta" className="mb-2">
          <Form.Label column sm="2">
            Meta (JSON)
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="textarea"
              name="meta"
              rows={10}
              value={formData.meta ?? ''}
              onChange={handleFormChange}
              placeholder='Enter valid JSON, e.g. {"key": "value"}'
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formPublic" className="mb-2">
          <Form.Label column sm="2">
            Public Set
          </Form.Label>
          <Col sm="10" className="d-flex align-items-center">
            <Form.Check
              type="checkbox"
              name="isPublic"
              label="Public"
              checked={!!formData.isPublic} // Ensure boolean is valid
              onChange={handleFormChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Col sm={12} className="d-flex justify-content-between">
            <Button variant="primary" type="button" onClick={handleSaveButtonPress} disabled={loadingSave}>
              {loadingSave ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button variant="secondary" type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
        </Form.Group>
      </Form>

      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteSentenceSet}
        itemName={formData.name ?? ''}
      />
    </div>
  );
};

export default SetForm;

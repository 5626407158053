import { useEffect, useState } from 'react';

import { Droppable, DroppableProps } from '@hello-pangea/dnd';

// Fixes a bug in @hello-pangea/dnd
// https://github.com/atlassian/@hello-pangea/dnd/issues/2396#issuecomment-1705672386
export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

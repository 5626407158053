import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LANG_MAP, PROCESS_PROMPTS } from '../constants';
import {
  Sentence,
  useAddMetaSentenceMutation,
  useTranslateMutation,
  useUpsertTranslationsMutation,
} from '../graphql/server-graphql-schema';
import { useCurrentSet } from '../hooks/currentSetHook';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';
import { useToast } from '../hooks/ToastNotificationProvider';
import { validateAnswerPOS } from '../pages/ai/verifyResult';
import SentenceList from './SentenceList';

const AiProcessForm: React.FC = () => {
  const { id: setId = 'set-id-not-provided' } = useParams();

  const initForm = {
    formLang: 'en-US',
    formSource: '',
    formTranslations: '',
    processType: 'T',
    reprocessNeeded: false,
  };

  const [formData, setFormData] = useState(initForm);
  const [errors, setErrors] = useState<any[]>([]);
  const [sentencesToBeTranslated, setSentencesToBeTranslated] = useState<{
    [key: string]: Sentence;
  }>({});

  const { refetch, sentenceSets } = useSentenceSetSets();
  const currentSet = useCurrentSet(sentenceSets, setId);

  const [upsertTranslations, { loading: loadingUpload }] = useUpsertTranslationsMutation();
  const [translate, { loading: loadingTranslate }] = useTranslateMutation();
  const [addMetaSentenceMutation, { loading: loadingAddMeta }] = useAddMetaSentenceMutation();

  const { showToast } = useToast();

  useEffect(() => {
    void getSentences();
  }, [formData.processType, formData.formLang, formData.reprocessNeeded]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const checked = e.target instanceof HTMLInputElement && e.target.type === 'checkbox' ? e.target.checked : undefined;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked !== undefined ? checked : value,
    }));
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      showToast({ message: 'Copied to clipboard!', variant: 'success', autohide: true });
    } catch (err) {
      showToast({ message: `Failed to copy text: ${(err as Error).message}`, variant: 'error', autohide: false });
    }
  };

  const copySourceToClipboard = () => {
    let p = PROCESS_PROMPTS[formData.processType as keyof typeof PROCESS_PROMPTS].trim();
    p = p.replace('DESTINATION_LANGUAGE', formData.formLang.trim());

    const textToCopy = `${p}\n\n${formData.formSource}`;

    void copyToClipboard(textToCopy);
  };

  const getSentences = async () => {
    if (!currentSet?.sentences) {
      return;
    }

    const sentences = currentSet.sentences;

    let sourceLines = '';
    const tempSentences: {
      [key: string]: Sentence;
    } = {};

    for (let i = 0; i < sentences.length; i++) {
      if (!formData.reprocessNeeded && formData.processType === 'POS' && sentences[i].meta?.pos) {
        continue;
      }

      if (
        !formData.reprocessNeeded &&
        formData.processType === 'T' &&
        sentences[i].translations.find((t) => t.lang === formData.formLang)
      ) {
        continue;
      }

      if (!formData.reprocessNeeded && formData.processType === 'S' && sentences[i].meta?.hints) {
        continue;
      }

      if (!formData.reprocessNeeded && formData.processType === 'H' && sentences[i].meta?.synonyms) {
        continue;
      }

      const txt = sentences[i].text.trim();

      if (txt) {
        tempSentences[i] = sentences[i];
        sourceLines = sourceLines + `${i}>${txt}\n`;
      }
    }
    setSentencesToBeTranslated(tempSentences);
    setFormData({ ...formData, formSource: sourceLines.trim() });
  };

  const processTranslation = async (data: Record<string, any>[]) => {
    const toUpload = [];

    for (const val of data) {
      const sid = sentencesToBeTranslated[val.id].id;

      const meta = val.s || val.h ? { s: val.s, h: val.h } : undefined;

      toUpload.push({
        sentenceId: sid,
        ...(meta && { meta }), // Include the meta object only if it exists
        text: val.t.trim(),
        transLang: formData.formLang,
      });
    }

    try {
      const response = await upsertTranslations({
        fetchPolicy: 'network-only',
        variables: {
          inputs: toUpload,
        },
      });

      await refetch(); // re-download the sentences sets

      if (response?.data?.upsertTranslations) {
        showToast({ message: 'Translations processed successfully.', variant: 'success', autohide: true });
      }
    } catch (error: any) {
      showToast({ message: `Error processing translations: ${error.message}`, variant: 'error', autohide: false });
    }
  };

  const processPartOfSpeech = async (data: Record<string, any>[]) => {
    const toUpload = [];
    const errors = [];

    if ('ok' !== validateAnswerPOS(data, sentencesToBeTranslated)) {
      showToast({ message: 'Invalid data format.', variant: 'error', autohide: false });
      return;
    }

    for (const val of data) {
      const key = Object.keys(val)[0];
      const pos = val[key];
      const sid = sentencesToBeTranslated[key].id;
      const text = sentencesToBeTranslated[key].text;

      if (pos.length !== text.trim().split(' ').length) {
        errors.push({ key, pos, text, sid });
      } else {
        toUpload.push({ id: sid, meta: { pos: pos } });
      }
    }

    try {
      const response = await addMetaSentenceMutation({
        fetchPolicy: 'network-only',
        variables: {
          inputs: toUpload,
        },
      });

      setErrors(errors);

      await refetch(); // re-download the sentences sets

      if (response?.data?.addMetaSentence) {
        showToast({ message: 'Part of Speech processed successfully.', variant: 'success', autohide: true });
      }
    } catch (error: any) {
      showToast({ message: `Error processing Part of Speech: ${error.message}`, variant: 'error', autohide: false });
    }
  };

  const processMeta = async (data: Record<string, any>[]) => {
    const toUpload = [];

    for (const [key, meta] of Object.entries(data)) {
      const sid = sentencesToBeTranslated[key].id;

      const toUploadMeta: { hints?: typeof meta.hints; synonyms?: typeof meta.s } = {};

      if (meta.h) {
        toUploadMeta.hints = meta.h;
      }

      if (meta.s) {
        toUploadMeta.synonyms = meta.s;
      }

      if (meta.h || meta.s) {
        toUpload.push({ id: sid, meta: toUploadMeta });
      }
    }

    try {
      const response = await addMetaSentenceMutation({
        fetchPolicy: 'network-only',
        variables: {
          inputs: toUpload,
        },
      });

      setErrors(errors);

      await refetch(); // re-download the sentences sets

      if (response?.data?.addMetaSentence) {
        showToast({ message: 'Meta processed successfully.', variant: 'success', autohide: true });
      }
    } catch (error: any) {
      showToast({ message: `Error processing meta: ${(error as Error).message}`, variant: 'error', autohide: false });
    }
  };

  const handleProcessData = async () => {
    try {
      if (!formData.formTranslations || !formData.formLang) {
        showToast({ message: 'The translations field is empty.', variant: 'error', autohide: false });
        return;
      }
      const data: Record<string, any>[] = JSON.parse(formData.formTranslations.trim());

      if (formData.processType === 'POS') await processPartOfSpeech(data);
      if (formData.processType === 'T') await processTranslation(data);
      if (formData.processType === 'S') await processMeta(data);
      if (formData.processType === 'H') await processMeta(data);

      setFormData({ ...formData, formTranslations: '' });
    } catch (error: any) {
      showToast({ message: `Error processing data: ${error.message}`, variant: 'error', autohide: false });
    }
  };

  // const handleAITranslate = async () => {
  //   try {
  //     const result = await translate({
  //       variables: {
  //         input: { values: { sentences: formData.formSource, lang: LANG_MAP[formData.formLang] }, name: 'tran' },
  //       },
  //     });

  //     if (result.data && Array.isArray(result.data.translate)) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         formTranslations: result.data.translate
  //           .map((c) => c.content)
  //           .join('')
  //           .trim(),
  //       }));

  //       showToast({ message: 'Translation completed successfully.', variant: 'success', autohide: true });
  //     }
  //   } catch (error: any) {
  //     showToast({ message: `Error in translation: ${error.message}`, variant: 'error', autohide: false });
  //   }
  // };

  return (
    <div className="mt-4">
      <Row>
        <Col>
          <h3>Add Translations:</h3>
          <Form>
            <section className="border rounded bg-body-tertiary p-5">
              <h4 className="mb-4">{currentSet?.name}</h4>

              <Form.Group as={Row} className="mb-5">
                <div className="d-flex justify-content-between w-100">
                  <div className="me-3">
                    <Form.Label htmlFor="formProcessType">Process Type</Form.Label>
                    <select
                      id="formProcessType"
                      name="processType"
                      value={formData.processType}
                      onChange={handleFormChange}
                      className="form-select"
                    >
                      <option value="T">Translation</option>
                      <option value="POS">Part Of Speech</option>
                      <option value="S">Synonyms</option>
                      <option value="H">Hints</option>
                    </select>
                  </div>

                  {formData.processType === 'T' && (
                    <div className="me-3">
                      <Form.Label htmlFor="formLang">Trans Language</Form.Label>
                      <select
                        id="formLang"
                        name="formLang"
                        value={formData.formLang}
                        onChange={handleFormChange}
                        className="form-select"
                      >
                        {Object.entries(LANG_MAP)
                          .filter((t) => t[0] !== 'es-MX')
                          .map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}

                  <div>
                    <Form.Check
                      type="checkbox"
                      id="reprocessNeeded"
                      name="reprocessNeeded"
                      label="Re-process Needed"
                      checked={formData.reprocessNeeded}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Row} controlId="formSource" className="mb-2">
                <Form.Label column sm="2">
                  Ai Input
                  <Button variant="link" type="button" onClick={copySourceToClipboard} className="p-0 ms-2">
                    <FontAwesomeIcon icon={light('copy')} className="me-1" />
                  </Button>
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    rows={10}
                    as="textarea"
                    name="formSource"
                    autoComplete="off"
                    value={formData.formSource}
                    onChange={handleFormChange}
                  />
                </Col>
              </Form.Group>
            </section>

            <section className="border rounded bg-body-tertiary p-5 mt-3">
              <Form.Group as={Row} controlId="formTranslations" className="mb-2">
                <Form.Label column sm="2">
                  Ai Output
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    rows={10}
                    as="textarea"
                    name="formTranslations"
                    value={formData.formTranslations}
                    onChange={handleFormChange}
                    placeholder="Paste translated sentences here"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-2">
                <Col sm={{ span: 10, offset: 2 }}>
                  <Button
                    variant="primary"
                    type="button"
                    disabled={formData.formTranslations.trim().split('\n').length === 0}
                    onClick={handleProcessData}
                  >
                    {loadingUpload || loadingAddMeta ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Uploading...
                      </>
                    ) : (
                      'Upload ' + formData.processType
                    )}
                  </Button>
                </Col>
              </Form.Group>
            </section>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <SentenceList transLang={formData.formLang} />
        </Col>
      </Row>
    </div>
  );
};

export default AiProcessForm;

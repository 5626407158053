import React from 'react';
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';

import { AVAILABLE_LANGS, LANG_MAP } from '../constants';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';
import { updateSettings } from '../store/coreSlice';
import { RootState } from '../store/store';

const LanguageSettings: React.FC = () => {
  const dispatch = useDispatch();

  const transLangs = useSelector((state: RootState) => state.core.settings.transLangs);
  const settings = useSelector((state: RootState) => state.core.settings);
  const { loading, refetch } = useSentenceSetSets();

  const handleSwitchChange = async (lang: string, isChecked: boolean) => {
    let updatedLangs = [...transLangs];
    if (isChecked && !updatedLangs.includes(lang)) {
      updatedLangs.push(lang);
    } else if (!isChecked && updatedLangs.includes(lang)) {
      updatedLangs = updatedLangs.filter((item) => item !== lang);
    }

    dispatch(updateSettings({ transLangs: updatedLangs }));
    await refetch({ lang: settings.lang, transLangs: updatedLangs });
  };

  return (
    <div className="row">
      <div className="col-sm-7">
        <h3>Preferred languages:</h3>
        Choose the languages in which you'd prefer to see your practice questions. You can select more than one language.
      </div>
      <div className="offset-sm-2 col-sm-3 mt-4">
        <Form>
          {AVAILABLE_LANGS.map((lang) => (
            <Form.Check
              key={lang}
              type="switch"
              id={`lang-switch-${lang}`}
              label={LANG_MAP[lang] || lang}
              value={lang}
              checked={transLangs.includes(lang)}
              onChange={(e) => handleSwitchChange(lang, e.target.checked)}
            />
          ))}
        </Form>
        {loading ? (
          <div className="mt-3">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Uploading...
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default LanguageSettings;

import { Sentence } from '../../graphql/server-graphql-schema';

export const validateAnswerPOS = (
  data: Record<string, any>[],
  sentencesToBeTranslated: {
    [key: string]: Sentence;
  },
): string => {
  if (!Array.isArray(data)) {
    return 'Data should be an array';
  }

  const validTags = ['S', 'X', 'V', 'A', 'B', 'Y', 'N', 'C', 'I', 'L', 'T'];

  for (const item of data) {
    const key = Object.keys(item)[0];
    const value = item[key];

    // Check if the key is a string representation of a number
    if (isNaN(Number(key))) {
      return `Key "${key}" is not a valid string of a number`;
    }

    // Check if the value is an array of strings
    if (!Array.isArray(value) || !value.every((v) => typeof v === 'string')) {
      return `Value for key "${key}" should be an array of strings`;
    }

    // Check if each element in the value array starts with a valid tag
    for (const word of value) {
      const tag = word.split(':')[0];
      if (!validTags.includes(tag)) {
        return `Invalid tag "${tag}" in value for key "${key}"`;
      }
    }

    // Check if the sentence with the key exists in sentencesToBeTranslated
    if (!sentencesToBeTranslated[key]) {
      return `Sentence with key "${key}" does not exist in sentencesToBeTranslated`;
    }
  }

  return 'ok';
};

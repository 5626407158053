import React, { useEffect, useState } from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FaCheck, FaPlus, FaRegEdit, FaVolumeUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { DragDropContext, Draggable } from '@hello-pangea/dnd';

import { SentenceSet, useUpdateSentenceSetOrderMutation } from '../../graphql/server-graphql-schema';
import useSentenceSetSets from '../../hooks/sentenceSetQueryHook';
import { StrictModeDroppable } from '../../libs/StrictModeDroppable';
import SetForm from './SetForm';

const CompSetList: React.FC = () => {
  const [sentenceSetsOrdered, setSentenceSetsOrdered] = useState<SentenceSet[]>([]);
  const [needSavingOrder, setNeedSavingOrder] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState<SentenceSet | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const navigate = useNavigate();
  const { sentenceSets, error, refetch, loading } = useSentenceSetSets();
  const [updateSentenceSetOrderMutation] = useUpdateSentenceSetOrderMutation();

  // Function to handle navigation while keeping the history stack for back navigation
  const handleNavClick = (path: string) => {
    navigate(path); // Navigate to the target path
  };

  // Fetch the sentence sets and keep them updated
  useEffect(() => {
    if (!loading && sentenceSets && sentenceSetsOrdered.length === 0 && sentenceSets.length > 0) {
      setSentenceSetsOrdered(sentenceSets);
    }
  }, [sentenceSets, loading, sentenceSetsOrdered]);

  // Refresh the sentence sets from the server
  const refreshSentenceSets = async () => {
    setIsRefreshing(true);
    const { data } = await refetch();
    if (data) {
      setSentenceSetsOrdered(data.getAllSentenceSets);
    }
    setIsRefreshing(false);
  };

  // Handle drag-and-drop order change
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(sentenceSetsOrdered);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update state optimistically to reflect the new order immediately
    setSentenceSetsOrdered(items);
    setNeedSavingOrder(true);
  };

  // Save the new sentence set order to the server
  const saveNewOrder = async () => {
    try {
      const ids = sentenceSetsOrdered.map((s) => s.id);

      // Perform optimistic update before the server response
      setNeedSavingOrder(false);

      // Perform the server mutation
      await updateSentenceSetOrderMutation({
        variables: { ids },
      });

      // Optionally refresh data, or ensure that server data matches the UI
      void refreshSentenceSets();
    } catch (error) {
      console.error('Error saving new order:', error);
      setNeedSavingOrder(true);
    }
  };

  // Trigger the edit form for a given sentence set
  const handleEditSentenceSet = (set: SentenceSet) => {
    const { __typename, sentences, ...restOfFormData } = { ...set };

    setFormData(restOfFormData); // Pass the set data into the form
    setIsFormVisible(true); // Show the form for editing
    window.history.pushState({}, ''); // Manually push a state to handle the back button properly
  };

  // Handle opening the form for adding a new set
  const handleAddButtonPress = () => {
    setFormData(null); // Reset form data to empty for adding a new set
    setIsFormVisible(true); // Show the form for adding a new set
    window.history.pushState({}, ''); // Manually push a state to handle the back button properly
  };

  // Handle closing the form and refreshing the list
  const handleCloseForm = () => {
    setIsFormVisible(false);
    setFormData(null);
    navigate('/manage-sets', { replace: true }); // Update URL to reflect that form is closed
    void refreshSentenceSets();
  };

  // Handle browser back navigation to close the form if visible
  useEffect(() => {
    const handlePopState = () => {
      if (isFormVisible) {
        setFormData(null);
        setIsFormVisible(false);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isFormVisible]);

  return (
    <div className="mt-4">
      {/* Button to add a new set */}
      {!isFormVisible && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          {isRefreshing && (
            <div className="d-flex align-items-center">
              <Spinner animation="border" role="status" size="sm" className="me-2">
                <span className="visually-hidden">Refreshing...</span>
              </Spinner>
              <div>Refreshing...</div>
            </div>
          )}
          <Button variant="secondary" className="ms-auto" onClick={handleAddButtonPress}>
            <FaPlus /> Add Set
          </Button>
        </div>
      )}

      {isFormVisible && (
        <SetForm
          initialData={formData}
          onClose={handleCloseForm} // Pass a function to handle closing the form
        />
      )}

      {!isFormVisible && (
        <>
          {error && <p>Error: {error.message}</p>}
          {loading && <div>Loading sets...</div>}

          {!loading && sentenceSetsOrdered.length > 0 ? (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <table
                      className={`table table-bordered table-sm table-striped ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <thead>
                        <tr className="text-center">
                          <th>#</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Public</th>
                          <th>Count / POS / Hints / Voice</th>
                          <th className="col-1">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sentenceSetsOrdered.map((set, index) => (
                          <Draggable key={set.id} draggableId={set.id} index={index}>
                            {(provided) => (
                              <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <td className="align-middle">{index + 1}</td>
                                <td className="align-middle">
                                  {set.name}
                                  <br />
                                  <span style={{ color: '#ddd' }}>{set.id}</span>
                                </td>
                                <td className="align-middle text-center">{set.type}</td>
                                <td className="align-middle text-center">{set.isPublic ? <FaCheck /> : '-'}</td>
                                <td className="align-middle text-center">
                                  {set.sentences?.length}
                                  {' / '}
                                  {set.sentences?.filter((sentence) => sentence.meta?.pos !== undefined).length}
                                  {' / '}
                                  {set.sentences?.filter((sentence) => sentence.meta?.hints !== undefined).length}
                                  {' / '}
                                  {set.sentences?.filter((sentence) => sentence.meta?.voiceGenerated !== undefined).length}
                                </td>
                                <td className="align-middle col-3">
                                  <div className="d-flex flex-row justify-content-between align-items-center">
                                    <Nav.Link onClick={() => handleNavClick(`/manage-sentence/${set.id}`)}>
                                      <FaPlus />
                                    </Nav.Link>
                                    <Nav.Link onClick={() => handleNavClick(`/manage-translation/${set.id}`)}>Ai</Nav.Link>
                                    <Nav.Link onClick={() => handleNavClick(`/manage-voice/${set.id}`)}>
                                      <FaVolumeUp />
                                    </Nav.Link>
                                    <Button
                                      variant="link"
                                      onClick={() => handleEditSentenceSet(set)} // Edit button will pass data to the form
                                    >
                                      <FaRegEdit />
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  )}
                </StrictModeDroppable>
              </DragDropContext>

              {needSavingOrder && (
                <Button className="mt-3" variant="primary" onClick={saveNewOrder}>
                  Save New Order
                </Button>
              )}
            </>
          ) : (
            !loading && <div>No Sets are available.</div>
          )}
        </>
      )}
    </div>
  );
};

export default CompSetList;

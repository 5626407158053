import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import Navbar from 'react-bootstrap/esm/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import hooks for navigation and location

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from '../store/store';

const NavBarComponent: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);

  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const location = useLocation();

  const handleLinkClick = (path: string) => {
    // Close the expanded state of the navbar
    setIsExpanded(false);

    // Navigate to the specified path if it's not the current location
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="mt-3 mb-4 bg-body-tertiary" expanded={isExpanded}>
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo128.png`}
            alt="Lengua.App"
            width="auto"
            height="80"
            className="d-none d-md-inline-block align-middle"
          />
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo64.png`}
            alt="Lengua.App"
            width="auto"
            height="64"
            className="d-inline-block d-md-none align-middle"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsExpanded(!isExpanded)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto mt-3 mt-md-0">
            <Nav.Link as={Link} onClick={() => handleLinkClick('/')} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} onClick={() => handleLinkClick('/privacy')} to="/privacy">
              Privacy
            </Nav.Link>
            <Nav.Link as={Link} onClick={() => handleLinkClick('/tou')} to="/tou">
              Terms of Service
            </Nav.Link>
            {auth.role === 'admin' ? (
              <>
                <Nav.Link as={Link} onClick={() => handleLinkClick('/synonyms')} to="/synonyms">
                  Synonyms
                </Nav.Link>
                <Nav.Link as={Link} onClick={() => handleLinkClick('/feedback')} to="/feedback">
                  Feedbacks
                </Nav.Link>
                <Nav.Link as={Link} onClick={() => handleLinkClick('/sets')} to="/sets">
                  Practice
                </Nav.Link>
                <Nav.Link as={Link} onClick={() => handleLinkClick('/manage-sets')} to="/manage-sets">
                  Sets
                </Nav.Link>
                <Nav.Link as={Link} onClick={() => handleLinkClick('/appsettings')} to="/appsettings">
                  Settings
                </Nav.Link>
                <Nav.Link as={Link} onClick={() => handleLinkClick('/reports')} to="/reports">
                  Reports
                </Nav.Link>
              </>
            ) : null}
          </Nav>
          <Nav>
            {auth.email === '' ? (
              <Nav.Link as={Link} to="/login" onClick={() => handleLinkClick('/login')} className="text-decoration-none ml-auto">
                Login
              </Nav.Link>
            ) : (
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={solid('user')} className="me-1" style={{ color: 'gray' }} />
                    Account
                  </>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/settings" onClick={() => handleLinkClick('/settings')}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/archive" onClick={() => handleLinkClick('/archive')}>
                  History
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarComponent;

import { Helmet } from 'react-helmet-async';

import AiProcessForm from '../components/TranslationForm';

const TranslationManagementPage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Add Translations</title>
      </Helmet>

      <AiProcessForm />
    </div>
  );
};

export default TranslationManagementPage;
